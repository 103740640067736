@import './variables';
@import './mixins';
@import '../../../node_modules/primereact/resources/themes/lara-light-indigo/theme.css';
@import '../../../node_modules/primereact/resources/primereact.min.css';
@import '../../../node_modules/primeicons/primeicons.css';
@import '../../../node_modules/bootstrap-icons/font/bootstrap-icons.scss';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
body,
html {
	height: 100%;
}
body {
	font-family: 'Inter', sans-serif;
	font-weight: 500;
}
//colors
.color-red {
	color: $color-red;
}
.bg-white {
	background: $white !important;
	th {
		background: $white !important;
	}
	td {
		background: $white !important;
	}
}
// buttons
.btn {
	font-size: 0.8rem;
	border-radius: 4px;
}
.btn-yellow {
	background: $golden-yellow;
	border-color: $golden-yellow;
	&:hover{
	background: $golden-yellow;
	border-color: $golden-yellow;
	}
}
.btn-success {
	background: $lemon-green;
	border-color: $lemon-green;
}
.btn-outline-primary {
	background: none;
	border-color: $tm-primary-color;
	color: $tm-primary-color;
	&:hover {
		background: $tm-primary-color;
		color: $white;
		border-color: $tm-primary-color;
	}
}
.btn-sm {
	font-size: 0.75rem;
	font-weight: 600;
}
.btn-primary {
	background: $tm-primary-color;
	border-color: $tm-primary-color;
	color: $white;
	&:hover {
		background: $tm-primary-color-dark;
		border-color: $tm-primary-color-dark;
	}
}
// validations
.error-text {
	color: $error;
	font-size: 0.8rem;
}
.invalid input {
	border: 1px solid #b40e0e;
	background-color: #fddddd;
}

.invalid input:focus {
	border-color: #ff8800;
	background-color: #fbe8d2;
}
.relative {
	position: relative;
}
.pointer {
	cursor: pointer;
}
.b-none {
	border: none;
	background: none;
}
.form-control {
	font-size: 0.8rem;
	border-radius: 4px;
}
label {
	font-size: 0.8rem;
}
.page-header {
	.page-title {
		font-size: 1.2rem;
		font-weight: 800;
		line-height: 36px;
		color: $tm-primary-color;
	}
}
.custom-data-tables {
	font-size: 0.8rem;
	border: 1px solid $table-border;
	&.p-datatable {
		.p-datatable-header {
			border-top: 0;
		}
		.p-paginator-bottom {
			border: 0;
		}
		.p-datatable-tbody {
			tr {
				background: none;
				cursor: pointer;
				td {
					padding: 0.5rem 0.75rem;
				}
			}
		}
		.p-datatable-thead {
			tr {
				th {
					padding: 0.5rem;
				}
			}
		}
	}
	table {
		margin-bottom: 0;
	}
}

.p-paginator {
	font-size: 0.8rem;
	.p-paginator-pages {
		.p-paginator-page {
			min-width: 2rem;
			height: 2rem;
			&.p-highlight {
				background: $tm-primary-color;
				color: $white;
				border-color: $tm-primary-color;
			}
		}
	}
	.p-dropdown {
		height: auto;
	}
}
.field-radiobutton {
	label {
		font-size: 0.8rem;
		margin-left: 0.4rem;
	}
}
.table-content {
	width: 100%;
	@include responsive(large-devices) {
		width: calc(100% - 250px);
	}
	@include responsive(extra-large-devices) {
		width: calc(100% - 350px);
	}
	@include responsive(x-extra-large-devices) {
		width: calc(100% - 500px);
	}
}

.form-wrapper {
	width: 100%;
	position: sticky;
	top: 0px;
	@include responsive(large-devices) {
		width: 250px;
	}
	@include responsive(extra-large-devices) {
		width: 350px;
	}
	@include responsive(x-extra-large-devices) {
		width: 500px;
	}
}
.button-wrapper {
	.btn {
		width: auto;
		margin-right: 0.65rem;
		@include responsive(large-devices) {
			width: 100%;
			margin-right: 0;
		}
		@include responsive(extra-large-devices) {
			width: auto;
			margin-right: 0.65rem;
		}
	}
}
.table > :not(:first-child) {
	border: none;
}
.table {
	thead {
		border-color: $table-border;
	}
	tbody {
		border-color: $table-border;
	}
	tfoot {
		border-color: $table-border;
	}
	tr {
		border-color: $table-border;
	}
	td {
		border-color: $table-border;
		vertical-align: middle;
	}
	th {
		border-color: $table-border;
	}
}
.card {
	&.dashboard-card {
		border: none;
		box-shadow: 0 2px 3px #eaedf2;
	}
	.card-body {
		.card-title {
			font-size: 1rem;
		}
	}
}
.action-column {
	min-width: 100px;
}
.p-inputtext {
	padding: 0.5rem 0.75rem;
	font-size: 0.85rem;
}
.p-calendar {
	.p-button {
		&.p-button-icon-only {
			padding: 0.25rem 0;
			background: none;
			border-color: $light-gray;
			color: $tm-primary-color;
		}
	}
}
// tabs
.p-tabview {
	.p-tabview-nav {
		li {
			.p-tabview-nav-link {
				padding: 0.6rem 1rem;
				font-size: 0.75rem;
				background: $lighter-gray;
				margin-right: 0.2rem;
				text-transform: uppercase;
			}
			&.p-highlight {
				.p-tabview-nav-link {
					background: $tm-primary-color;
					color: $white;
					border: none;
				}
			}
		}
	}
	.p-tabview-panels {
		padding: 0.5rem 0;
	}
}
.programs-table-wrapper {
	font-size: 11px;
	font-weight: 600;
	line-height: normal;
	width: 100%;
	@include responsive(medium-devices) {
		max-height: calc(100vh - 220px);
	}
	.scheduler-fields-wrapper {
		min-width: 1200px;
		width: 100%;
		.parts-component {
			width: 1000px;
			// min-width: 1000px;
			@include responsive(x-extra-large-devices) {
				width: auto;
				flex-grow: 1;
			}
			table {
				table-layout: fixed;
				th {
					text-transform: uppercase;
					padding: 0.1rem 0.3rem;
					vertical-align: middle;
					&.thead-fixer-th {
						height: 40px;
						max-height: 40px;
						min-height: 40px;
						width: 70px;
						max-width: 70px;
						min-width: 70px;
						word-break: break-word;
						&:first-child {
							min-width: 150px;
							width: 150px;
							max-width: 150px;
						}
						&.th-description {
							min-width: 200px;
							width: 200px;
							max-width: 200px;
						}
						&:first-child {
							position: sticky;
							left: -2px;
							background: $table-bg !important;
						}
					}
					&.thead-small-th {
						height: 30px;
						max-height: 30px;
						min-height: 30px;
						font-size: 0.6rem;
						font-weight: 400;
						min-width: 90px;
						max-width: 90px;
						width: 90px;
					}
				}
				td {
					padding: 0.3rem;
					&:first-child {
						position: sticky;
						left: -2px;
						background: $table-bg !important;
					}
				}
			}
		}
		.weeek-component {
			width: 800px;
			min-width: 800px;
			@include responsive(x-extra-large-devices) {
				width: auto;
				flex-grow: 1;
			}
			table {
				table-layout: fixed;
				.sales-tr input {
					border: none !important;
					pointer-events: none;
				}
				th {
					&.thead-fixer-th {
						height: 40px;
						max-height: 40px;
						min-height: 40px;
						vertical-align: middle;
						.sales-input {
							width: 100%;
							height: 90%;
							border: 2px solid #dee2e6;
							border-radius: 0.1rem;
						}
						.sales-field {
							width: 100%;
							height: 100%;
							border: 1px solid #dee2e6;
						}
					}
				}
				td {
					padding: 0.3rem;
				}
			}
			.td-input-wrapper {
				width: 50px;
				max-width: 50px;
				min-width: 50px;
				display: flex;
				align-items: center;
				padding: 0 2px;
			}
			&.summary-of-runouts {
				width: 1650px !important;
				min-width: 1650px !important;
			}
		}
		.thead-fixer {
			height: 40px;
			max-height: 40px;
			min-height: 40px;
			width: 62px;
			max-width: 62px;
			min-width: 62px;
			word-break: break-word;
			display: flex;
			align-items: center;
			&.description {
				min-width: 100px;
				width: 100px;
				max-width: 100px;
			}
		}
		// .thead-small{
		//   height: 30px;
		//   max-height: 30px;
		//   min-height: 30px;
		//   font-size: .6rem;
		//   font-weight: 400;
		//   display: flex;
		//   align-items: center;
		//   text-transform: none;
		// }
		.negative-value-as-red {
			background-color: #fa9494 !important;
		}
		.td-input-wrapper {
			width: 50px;
			max-width: 50px;
			min-width: 50px;
			height: 30px;
			max-height: 30px;
			min-height: 30px;
			display: flex;
			align-items: center;
			margin-left: auto;
			padding: 0 2px;
			input {
				width: 100%;
				text-align: right;
				border: 1px solid $light-gray;
			}
			&.square-footage {
				width: 60px;
				max-width: 60px;
				min-width: 60px;
			}
		}
		.schedule-shipping-table {
			.thead-fixer {
				height: 40px;
				max-height: 40px;
				min-height: 40px;
				width: 62px;
				max-width: 62px;
				min-width: 62px;
				display: flex;
				align-items: center;
			}
			td {
				padding: 0.3rem;
			}
			th {
				padding: 0.1rem 0.3rem;
			}
		}
	}
	// &.edit-disabled {
	// 	input {
	// 		border: none;
	// 		pointer-events: none;
	// 	}
	// }
	&.programs-table-wrapper-no-tab {
		@include responsive(medium-devices) {
			max-height: calc(100vh - 120px);
		}
	}
}

.edit-disabled {
	input {
		border: none !important;
		pointer-events: none;
	}
}

.square-footage.parts-component {
	width: 1094px !important;
}
.upload-parts {
	font-size: 12px;
}
.custom-modal {
	width: 90%;
	@include responsive(medium-devices) {
		width: 500px;
	}
}
.p-dialog {
	.p-dialog-header,
	.p-dialog-footer {
		padding: 1rem 1.5rem;
	}
	.p-dialog-content {
		padding-bottom: 0.5rem;
	}
}

// calendar
.my-calendar-wrapper {
	.fc {
		.fc-toolbar {
			.fc-button {
				background: $white;
				border-color: $table-border;
				color: $text-gray;
				font-size: 0.8rem;
				@include border-radius(4px);
				.fc-icon-chevron-left,
				.fc-icon-chevron-right {
					font-size: 0.8rem;
				}
				&:enabled:hover,
				&:disabled,
				&.fc-dayGridMonth-button.fc-button-active {
					background: $tm-primary-color;
					border-color: $tm-primary-color;
				}
				&:first-child {
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}
				&:last-child {
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}
				&:focus {
					box-shadow: none;
				}
			}
		}
		.fc-toolbar-title {
			font-size: 1.2rem;
		}
		.fc-col-header-cell-cushion,
		.fc-daygrid-day-number {
			color: $text-gray;
			text-decoration: none;
		}
		.fc-view-harness {
			.fc-event {
				&.fc-daygrid-block-event {
					background: $pastal-blue;
					border-color: $pastal-blue;
					@include border-radius(0);
					font-size: 0.8rem;
					padding: 0 0.5rem;
					cursor: pointer;
				}
				.fc-event-title {
					color: $black;
				}
			}
			th {
				font-size: 0.9rem;
			}
		}
		.fc-daygrid-day-number {
			font-size: 0.8rem;
		}
	}
}
.jobsite-wrapper {
	div {
		margin-right: 0.2rem;
		&:last-child {
			span {
				display: none;
			}
			margin-right: 0;
		}
	}
}
.modal-title-small {
	font-size: 1.1rem;
}
.p-dialog {
	.p-dialog-header {
		.p-dialog-title {
			font-size: 1.1rem;
		}
	}
}
.btn:focus {
	box-shadow: none !important;
}
.program-status {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	&.live {
		background: $lemon-green;
	}
	&.closed {
		background: $text-gray;
	}
}
.height-fixer {
	height: 30px;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	display: flex;
	align-items: center;
}

.dashboard-title {
	font-size: 1.1rem;
	font-weight: 700;
	.small {
		font-size: 0.8rem;
	}
}
.card {
	&.card-purple {
		background: $light-purple;
	}
	&.card-orange {
		background: $light-orange;
	}
	&.card-blue {
		background: $light-blue;
	}
	&.card-green {
		background: $light-green;
	}
	.card-label {
		font-size: 0.75rem;
		color: $tm-primary-color-dark;
		font-weight: 800;
	}
	.card-icon-wrap {
		width: 40px;
		height: 40px;
		background: rgba($color: $light-gray, $alpha: 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.card-count {
		font-size: 1.3rem;
		color: $black;
		line-height: normal;
		font-weight: 700;
	}
}
.opactiy-half {
	opacity: 0.1;
}
.gray-bg {
	background: $lighter-gray;
}
.calendar-wrapper {
	.p-calendar {
		width: 100%;
		.p-datepicker {
			border: none;
			table {
				&.p-datepicker-calendar {
					table-layout: fixed;
				}
				td {
					span {
						width: auto;
						height: auto;
						font-size: 0.9rem;
						&:hover {
							background: none;
						}
					}
					&.p-datepicker-today {
						border-radius: 0;
						background: $color-red;
						color: $white;
						span {
							background: none;
							border: none;
							color: $white;
							&:hover {
								background: none;
								color: inherit;
								border: none;
							}
							&:focus {
								box-shadow: none;
							}
						}
					}
				}
			}
		}
	}
}

.calendar-schedule-wrapper {
	max-height: 400px;
	overflow: auto;
	ul {
		margin: 0;
		padding: 0 1rem;
		list-style: none;
		li {
			padding: 0.4rem 0.2rem 0.4rem 1rem;
			font-size: 0.8rem;
			position: relative;
			line-height: normal;
			display: flex;
			align-items: center;
			&:before {
				content: '';
				position: absolute;
				width: 7px;
				height: 7px;
				background: $text-gray;
				left: 0;
				transform: rotate(45deg);
			}
		}
	}
}
.square-footage.buildable-units {
	width: 100%;
	min-width: 1892px;
}
.buildable-units {
	width: 100%;
	min-width: 1800px;
	@include responsive(x-extra-large-devices) {
		width: auto;
		flex-grow: 1;
	}
	table {
		table-layout: fixed;
		th {
			text-transform: uppercase;
			padding: 0.1rem 0.3rem;
			vertical-align: middle;
			&.thead-fixer-th {
				height: 40px;
				max-height: 40px;
				min-height: 40px;
				width: 70px;
				max-width: 70px;
				min-width: 70px;
				word-break: break-word;
				&:first-child {
					min-width: 150px;
					width: 150px;
					max-width: 150px;
				}
				&.th-description {
					min-width: 200px;
					width: 200px;
					max-width: 200px;
				}
			}
			&.thead-small-th {
				height: 30px;
				max-height: 30px;
				min-height: 30px;
				font-size: 0.6rem;
				font-weight: 400;
				min-width: 90px;
				max-width: 90px;
				width: 90px;
			}
		}
		td {
			padding: 0.3rem;
		}
	}
}
.small-height-fixer {
	height: 30px;
	min-height: 30px;
	max-height: 30px;
}

.custom-tables {
	font-size: 0.8rem;
	table {
		min-width: 1500px;
		th {
			background: $thead-bg !important;
		}
	}
}
.custom-table-half-width {
	min-width: 750px !important;
}
.custom-tables-small {
	font-size: 0.8rem;
	table {
		th {
			background: $thead-bg !important;
		}
	}
}
.td-input-wrapper {
	width: 50px;
	max-width: 50px;
	min-width: 50px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 3px;
}
.dashboard-calendar-program-title {
	font-size: 1rem;
	font-weight: 600;
	border-bottom: 1px dashed $light-gray;
	padding-bottom: 0.3rem;
}
.dcp-program-title {
	font-size: 0.8rem;
	font-weight: 600;
}
.main-category-item {
	font-weight: 800;
	color: $tm-primary-color;
	font-size: 0.77rem;
}
.sub-category-item {
	font-weight: 800;
	color: $tm-primary-color;
	font-size: 0.72rem;
	padding-left: 0.5rem;
}
.program-listing-table {
	table {
		min-width: 1630px;
	}
}
.sticky-header-table {
	border-collapse: separate;
	border-spacing: 0;
	thead {
		position: sticky;
		top: 0;
		z-index: 2;
	}
	tr {
		td,
		th {
			border: 1px solid $table-border;
		}
	}
}
.last-uploaded-date {
	.p-tag {
		background: none;
		font-style: italic;
		color: $lemon-green;
		font-size: 0.65rem;
	}
}
.percentage-of-completion {
	@include responsive(medium-devices) {
		height: calc(100vh - 110px);
	}
	th {
		min-width: 100px;
	}
}
.content-end {
	.td-input-wrapper {
		width: auto;
		max-width: inherit;
	}
}
.p-datatable {
	font-family: 'Inter', sans-serif;
	font-size: 0.8rem;
	font-weight: 500;
	.p-datatable-tbody {
		tr {
			color: #212529;
		}
	}
}
