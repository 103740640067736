@import '../../assets/scss/variables';
.sidebar-wrapper{
    width: 240px;
    height: 100%;
    background: $tm-primary-color;
    flex-shrink: 0;
    color: $text-color;
    padding: 0 1rem;
    &.toggle-menu{
        width:60px;
        padding: 0 .5rem;
        .sidebar-footer{
            display: none;
        }
        
    .sidebar-lists{
        span{
            display: none;
        }
    }
    .report-active{
        .reports-list{
            display: none;
        }
    }
    .sidebar-logo{
        height: 20px;
        padding: 0!important;
        margin: auto;
        .logo-normal{
            display: none;
        }
        .logo-icon{
            display: block;
            max-height: 100%;
        }
    }
    }
}
.spinner {
    left: '50%'; 
    top: '50%';
}