$tm-primary-color: #00235a;
$tm-primary-color-dark: #012052;
$white: #ffffff;
$black:#000000;
$golden-yellow: #f3ca3e;
$login-input-border:#e9f2ff;
$error: #FF3D00;
$text-color: #e9f2ff;
$danger:#dc3545;
$light-gray:#d9d9d9;
$lighter-gray:#f1f1f1;
$table-bg:#f8f8f8;
$table-border:#dee2e6;
$lemon-green:#2ac940;
$lighter-gray:#f2f2f2;
$color-red:#d30303;
$text-gray:#969696;
$pastal-blue:#b2d9ea;
$light-purple :#fdf4f5;
$light-orange:#fffce4;
$light-blue:#eff3ff;
$light-green:#edffef;
$thead-bg :#f8f9fa;

