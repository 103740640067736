@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';
.login-bg{
    background: url(../../assets/img/login-bg.jpg) left center;
    background-size: cover;
}
.login-form-fields{
    width: 450px;
    background: $tm-primary-color;
    padding-left: 50px;
    padding-right: 50px;
    .login-log{
        height: 60px;
        margin-bottom: 1rem;
        img{
            max-height: 100%;
        }
    }
    label{
        color: $white;
        font-size: .9rem;
    }
    .form-control{
        background: none;
        border-color: $login-input-border;
        color: $white;
        min-height: 40px;
    }
    a{
        color: $white;
        font-size: .9rem;
        text-decoration: none;
    }
    .btn{
        font-size: 1rem;
        font-weight: 600 ;
    }
    @include responsive(extra-smaller-devices) {
       width: 100%;
    }
}
