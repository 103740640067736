.pointer {
  cursor: pointer;
}
.progress-wrap {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100vh;
    background-color: #00000014;
    z-index: 2;
}
.v-align {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.clear-all {
  text-decoration: underline;
  color: blue;
}
.is-disabled
{
  pointer-events: none;
  opacity: 0.6;
}
.green-clr {
  color: green;
}
.red-clr {
  color: red;
}
.fs-12px {
  font-size: 12px;
  line-height:18px;
}
.fs-14px {
  font-size: 14px;
  line-height:20px;
}
.fs-16px {
  font-size: 16px;
  line-height:22px;
}