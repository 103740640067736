@import '../../../assets/scss/variables';
.notification{
    cursor: pointer;
    .badge{
        position: absolute;
        padding: 3px 4px;
        margin-left: -10px;
        font-size: .7rem;
        font-weight: 500;
        &.badge-danger{
            background: $danger;
            color: $white;
        }
    }
}
.user-pic{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: $tm-primary-color;
    color: $white;
    font-weight: 600;
    padding: .7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .7rem;
}
.user-name{
    font-size: .7rem;
    font-weight: 600;
}
.user-logout{
    @extend .user-pic;
    background: $light-gray;
    color: $black;
    cursor: pointer;
}
