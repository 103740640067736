@import '../../../assets/scss/variables';
.sidebar-logo{
    max-height: 60px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        max-width: 100%;
    }
    .logo-icon{
        display: none;
    }
}
.sidebar-footer{
    font-size: .65rem;
    text-align: center;
}
.reports-list{
    display: none;
    padding-left: 1rem;
    a{
        display: flex;
    }
}
.report-active{
    .reports-list{
        display: block;
    }
}
.sidebar-lists{
    font-size: .8rem;
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            padding: .5rem  0;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            a{
                color: $text-color;
                text-decoration: none;
                width: 100%;
                &:hover{
                    color: $white;
                }
            }
        }
    }
    .sidebar-dropdown{
        position: relative;
        &:after{
            content: "\F282";
            font-family: "bootstrap-icons" !important;
            position: absolute;
            right: 0;
            pointer-events: none;
            top: 15px;
        }
        &.report-active{
            &:after{
                content: "\F286";
                font-family: "bootstrap-icons" !important;
                position: absolute;
                right: 0;
                pointer-events: none;
                top: 15px;
            }
            li{
                font-size: .7rem;
                padding: .4rem 0;
            }
        }
    }
}
.is-active {
    position: relative;
   &::before{
    width: 4px;
    height: 95%;
    background: $golden-yellow;
    content: "";
    position: absolute;
    left: -10px;
   }
}